<template>
  <div>  
    <patient-info :patient-data="patientData" :loading="loading.patientInfo" :use-history-data="true" :reg-id="$route.params.pageSlug" /> 
    <div class="content">
      <div class="page-title pt-0 pb-3" v-if="viewType == 'VIEW'">
        <a href="javascript:;" @click="back" class="text-dark">
          <i class="icon-arrow-left52 mr-1"></i>
          <span class="font-weight-semibold">Kembali</span>
        </a>
      </div>

      <template v-if="isCritical">
        <intensive @changeViewType="changeViewType(...arguments)" />
      </template>

      <template v-else>
        <template v-if="isObsgyn">
          <MEOWS @changeViewType="changeViewType(...arguments)" />
        </template>
        <template v-else>
          <PEWS @changeViewType="changeViewType(...arguments)" v-if="showPEWS" />
          <EWS @changeViewType="changeViewType(...arguments)" v-else-if="showEWS" />
        </template>
      </template>

      <balance-cairan @changeViewType="changeViewType(...arguments)" />
    </div>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import Gen from '@/libs/Gen'

import PatientInfo from '@/components/Ranap/PatientInfo.vue'
import PEWS from './PEWS/PEWS.vue'
import EWS from './EWS/EWS.vue'
import MEOWS from './MEOWS/MEOWS.vue'
import Intensive from './Intensive/Intensive.vue'
import BalanceCairan from './BalanceCairan/BalanceCairan.vue'

import MonitoringOptions from '@/libs/MonitoringOptions'

export default {
  extends: GlobalVue,
  components: {
    PatientInfo,
    PEWS, EWS, MEOWS,
    Intensive, BalanceCairan
  },
  data(){
    return {
      loading: {
        patientInfo: false
      },

      patientData: {
        ap_usia_with_ket: ''
      }
    }
  },

  mounted(){
    this.getPatientInfo()
  },

  methods: { 
    changeViewType(view){
      this.$router.push({ name: this.modulePage, params: { ...this.$route.params }, query: { viewType: view } })
    },

    getPatientInfo(){
      this.$set(this.loading, 'patientInfo', true)
      Gen.apiRest(
        "/do/"+'RanapPasienPerawatan',
        { 
          data: {
            type: "get-data-pasien",
            id_registrasi: this.$route.params.pageSlug
          } 
        }, 
        "POST"
      ).then(resp => {
        this.$set(this, 'patientData', resp.data.data)
        this.$set(this.loading, 'patientInfo', false)
      })
    },
    
    back() {
      if(this.user.levelId == this.uDokter || this.user.levelId == this.uDokterFisio || this.user.levelId == this.uDokterUGD){
        this.$router.push({name : 'RanapPasienPerawatanDPJP'})
      }else{
        this.$router.push({name : 'RanapPasienPerawatan'})
      }
    },
  },
  watch: {
    $route() {
      this.getPatientInfo()
    },
  },

  computed: {
    showPEWS(){
      return this.patientData.ap_usia >= 0 && this.patientData.ap_usia <= 18
    },
    showEWS(){
      return this.patientData.ap_usia > 18
    },

    isBayi(){
      let arrUsia = this.patientData.ap_usia_with_ket.split(" ")
      if((arrUsia||[]).length){
        arrUsia = arrUsia.map(usia => (+usia)).filter(usia => (!isNaN(usia)))

        if(arrUsia[0] == 0 && arrUsia[1] == 0 && arrUsia[2] <= 28){
          return true
        }

        return false
      } else {
        return false
      }
    },

    isCritical(){ // cek pasien yang berada di ruangan icu, nicu, dan picu
      return MonitoringOptions.CriticalPatient.
      indexOf(this.patientData.mranjr_code) >= 0
    },

    isObsgyn(){
      return MonitoringOptions.ObsgynPatient.
        indexOf(this.patientData.mranjr_code) >= 0
    },
    viewType(){
      return this.$route.query.viewType || "VIEW"
    }

  }
}
</script>