<template>
  <div>
    <form-data v-if="parentData.viewType === 'FORM_BALANCE_CAIRAN'" ref="formBalanceCairan" />
  </div>
</template>

<script>
import FormData from './FormData.vue'
import Gen from '@/libs/Gen'

const _ = global._

export default {
  components: {
    FormData
  },
  data(){
    return {
      tekananDarah: {
        data: {}
      },
      balanceCairan: {},
      ews: {},
      hermodinamy: {
        data: {},
      },

      tHistoryData:{
        data:[],
        page:{
          total_data:0,
          per_page: 10,
          current_page:1,
          is_loading:false
        }
      },

      pageNoTData: 1,


      row: {},
      mrValidation: {},
      mrCairan: [],
      mrDarahBalanceCairan: [],
    }
  },

  mounted() {
    if(this.parentData.viewType === "FORM_BALANCE_CAIRAN") {
      this.getFormData()
    }
  },

  methods: {
    getHistoryData(page=1){
      this.$set(this.tHistoryData.page, 'is_loading', true)
      Gen.apiRest(
        "/get/"+'RanapBalanceCairan',
        {
          params: {
            page,
            type: 'get-history',
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res=>{
        const { historyData } = res.data
        this.$set(this, 'tHistoryData', historyData)

        this.$set(this.tHistoryData.page, 'is_loading', false)
      })
    },

    getFormData(){
      Gen.apiRest(
        "/get/"+'RanapBalanceCairan',
        {
          params: {
            type: 'get-form',
            id_registrasi: this.$route.params.pageSlug
          }
        },
      ).then(res=>{
        const { 
          row, mrCairan, mrDarahBalanceCairan, mrValidation
        }  = res.data

        this.$set(this, 'formData', row)
        this.$set(this, 'mrCairan', mrCairan)
        this.$set(this, 'mrDarahBalanceCairan', mrDarahBalanceCairan)
        this.$set(this, 'mrValidation', mrValidation)
        const compFormBalanceCairan = this.$refs.formBalanceCairan
        compFormBalanceCairan.setFormData(row)
      })
    },

    doSubmitBalanceCairan(data){
      this.$swal.fire({
        title: 'Loading...',
        showConfirmButton: false,
        allowOutsideClick: false,
        didOpen: () => {
          this.$swal.showLoading()
        }
      })

      Gen.apiRest(
        "/do/"+'RanapBalanceCairan',
        { 
          data: {
            ...data,
            id_registrasi: this.$route.params.pageSlug
          } 
        }, 
        "POST"
      ).then(resp => {
        this.$swal.close()
        this.$swal({
          title: resp.data.message,
          icon: 'success',
          confirmButtonText: 'Ok'
        }).then(result => {
          if (result.value) {
            this.$set(this.$emit('changeViewType', 'VIEW'))
          }
        })
      }).catch(()=>{
        this.$swal.close()

        return this.$swal({
          icon: 'error',
          text: 'Terjadi Kesalahan Yang Tidak Diketahui'
        })
      })
    },

    changePage(page){
      this.$set(this, 'pageNoTData', page)
    }
  },

  computed: {
    parentData(){
      return this.$parent
    },
    moduleRole(){
      return this.parentData.moduleRole
    },

    isBayi(){
      return this.$parent.isBayi
    },

    isCritical(){
      return this.$parent.isCritical
    }

  },

  watch: {
    'parentData.viewType'(v){
      if(v === "FORM_BALANCE_CAIRAN") {
        this.getFormData()
      }
    },
    'pageNoTData'(v){
      this.getHistoryData(v)
    }
  }
}
</script>